<template>
  <div>
    <div class="vd_search_group">
      <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getPodtsNow()">
        <el-row>
          <el-col :md="8">
            <el-form-item label="产品卡号:">
              <el-input size="small" v-model.trim="searchForm.rfid_no" clearable placeholder="请刷卡"></el-input>
            </el-form-item>
           
          </el-col>
          <el-col :md="8">
              <el-form-item label="金检类型:" size="small"> 
                <el-select v-model="searchForm.rfid_prcs_type" clearable placeholder="请选择">
                <el-option
                 v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
      
        </el-row>
        <el-row>
          <el-col :md="14">
            <el-form-item label="金检时间:">
              <el-date-picker
                size="small"
                v-model="searchForm.timeValue"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getPodtsNow()" class="vg_ml_16">查询</el-button>
              <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="vd_button_group vg_mtb_16">
    </div>
    <el-row>
      <el-col :md="24">
        <el-table ref="multiTable" v-loading="loadingFlag" class="vg_cursor" :data="tableData" border>
          <el-table-column label="产品卡号" prop="rfid_no"/>
          <el-table-column label="金检类型" prop="rfid_prcs_type" :formatter="formattRfid"/>
          <el-table-column label="未通过数量" prop="podr_prod_failnum"/>
          <el-table-column label="产品数量" prop="podr_prod_num" show-overflow-tooltip/>
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina"/>
          <el-table-column label="金检时间" prop="create_time" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.create_time">
                {{ scope.row.create_time | formatDate}}
              </span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch" ref="pubPagination"></pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {podtAPI} from "@api/modules/podt"
import pubPagination from "@/components/common/pubPagination";
import helper from "@assets/js/helper.js"
export default {
name: "podtList",
  components: {
    pubPagination,
  },
  data() {
    return {
      searchForm: {
        rfid_no:null,
        timeValue:{
          startTime:null,
          endTime:null
        },
        rfid_prcs_type:null
      },
      currentPage: 1,
      totalPage:0,
      tableData: [],
      tableData1: [],
      tableDataList:[],
      multiSelection: [],
      btn:{},
      podtType:[
        {id:1,label:'皮壳金检'},
        {id:2,label:'手工金检'},
        {id:3,label:'内包金检'},
      ],
      loadingFlag:true,
      options:[
        // {
        //   value: '0',
        //   label: '机缝金检'
        // }, 
        {
          value: '1',
          label: '皮壳金检'
        },
        {
          value: '2',
          label: '手工金检'
        }, {
          value: '3',
          label: '内包金检'
        }, 
        // {
        //   value: '4',
        //   label: '外包金检'
        // }, {
        //   value: '5',
        //   label: '充棉金检'
        // },   {
        //   value: '6',
        //   label: '手工金检'
        // },
        // {
        //   value: '7',
        //   label: '检验金检'
        // }, {
        //   value: '8',
        //   label: '内包金检'
        // }, 
        // {
        //   value: '9',
        //   label: '外包金检'
        // }
      ]
    }
  },
  created() {
    this.initData()
  },
  watch:{
  },
  filters:{
    // 产品类型转换
    formatPodtType(row){
      if(row === 1){
        return '新款'
      }else if(row === 2){
        return '老款'
      }else if(row === 3){
        return '修改款'
      }
    },
    // 时间转换
    formatDate(row) {
      return helper.toStringDate(row)
    },
  },
  methods: {
    initData(){
      this.loadingFlag = true
      this.getPodts();
    },
    // 获取产品信息
    getPodts() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
      get(podtAPI.getRfidPrcsByPodtId,
          {
            rfid_no:this.searchForm.rfid_no,
            podr_prod_id: this.$route.query.form_id,
            page_no: this.currentPage,
             start_time: timeNewVal.startTime,
            end_time: timeNewVal.endTime,
            rfid_prcs_type:this.searchForm.rfid_prcs_type
          })
          // this.$route.query.form_id
          .then(res => {
            if(res.data.code === 0){
              this.tableData = res.data.data.list;
              this.totalPage = res.data.data.total
              this.btn = res.data.data.btn;
              setTimeout(() => {
                this.loadingFlag = false
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 刷新
    buttonRefresh(){
      this.searchForm = {
        rfid_no:null,
      };
      this.loadingFlag = true
      this.currentPage = 1
      this.initData()
      this.$refs.pubPagination.currentPage = 1
    },
    // 查询方法
    getPodtsNow(){
      this.loadingFlag = true
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.initData()
    },
    // 分页查询
    changePageSearch(val){
      this.currentPage = val
      this.initData()
    },
    // 金检类型
    formattRfid(row){
      let temp = this.podtType.filter(item=>{return item.id === row.rfid_prcs_type})
      return temp[0].label
    }
  }
}
</script>

<style scoped>

</style>