<template>
  <el-table :data="podrForm.podr_prod_plmtrl_list" border @selection-change="handleSelectionChange2" class="el-table-two">
    <el-table-column type="selection" width="48" align="center"></el-table-column>
    <el-table-column label="序号" width="48" align="center">
      <template slot-scope="scope">
        {{ scope.$index+1 }}
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">所用物料</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'podr_prod_plmtrl_list.'+scope.$index+'.prod_parent_ids'" :rules="[{required:true}]">
          <!-- <el-input show-word-limit maxlength="30" v-model="podrForm.podr_prod_plmtrl_list[scope.$index].prod_parent_ids" placeholder="暂无所用物料"></el-input> -->
          <!-- <el-select @change="plmtrlChange(scope)" v-if="flag" v-model="podrForm.podr_prod_plmtrl_list[scope.$index].prod_parent_list" multiple placeholder="请选择">
            <el-option
              v-for="item in podrForm.podr_prod_plmtrl_list[scope.$index].plmtrlOption"
              :key="item.prod_mtrl_id"
              :label="item.mtrl_no"
              :value="item.prod_mtrl_id">
            </el-option>
          </el-select> -->
          <div class="vd_dis">
            <el-tag
              v-for="tag in podrForm.podr_prod_plmtrl_list[scope.$index].prod_parent_listO"
              :key="tag.key"
              class="vd_magr3"
              :closable="!isShow"
              @close="handleClose(scope,tag)"
              type="success">
              {{tag.mtrl_no}}
            </el-tag>
            <el-popover
              placement="bottom"
              width="300" 
              :disabled="isShow"
              trigger="click">
              <el-tag
              v-for="tag in podrForm.podr_prod_plmtrl_list[scope.$index].plmtrlOption"
                :key="tag.podr_prod_mtrl_id"
                class="vg_cursor vd_magr3"
                @click="plmtrlChange(scope,tag)"
                type="info">
                {{tag.mtrl_no}}
              </el-tag>
              <el-button 
                slot="reference"
                plian
                 :disabled="isShow"
                class="el-icon-plus vg_pointer vd_clire"
              ></el-button>
              <!-- <el-button icon="el-icon-plus vd_clire" plian></el-button> -->
            </el-popover>
          </div>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column width="150">
      <template slot="header">
        <span class="vg_deep_red">加工类型</span>
      </template>
      <template slot-scope="scope">
        <el-link @click="compListChange(scope,0)" :disabled="isShow" type="primary" class="vg_cursor vg_mr_8">复合</el-link>
        <el-link @click="compListChange(scope,1)" :disabled="isShow" type="primary" class="vg_cursor vg_mr_8">印花</el-link>
        <el-link @click="compListChange(scope,2)" :disabled="isShow" type="primary" class="vg_cursor">电绣</el-link>
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">物料编号</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'podr_prod_plmtrl_list.'+scope.$index+'.mtrl_no'" :rules="[{required:true}]">
          <el-input @change="plmtrlChangeCut(scope)" v-model="podrForm.podr_prod_plmtrl_list[scope.$index].mtrl_no" @input ="podrForm.podr_prod_plmtrl_list[scope.$index].mtrl_no = helper.keepEngNum2(podrForm.podr_prod_plmtrl_list[scope.$index].mtrl_no)" maxlength="30" show-word-limit placeholder="暂无物料编号"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="物料名称">
      <template slot="header">
        <span class="vg_deep_red">物料名称</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'podr_prod_plmtrl_list.'+scope.$index+'.mtrl_name'" :rules="[{required:true}]">
          <el-input v-model="podrForm.podr_prod_plmtrl_list[scope.$index].mtrl_name" maxlength="30" show-word-limit placeholder="暂无物料名称"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="" >
      <template slot="header">
        <span class="vg_deep_red">物料单位</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="1000" :prop="'podr_prod_plmtrl_list.'+scope.$index+'.mtrl_unit'" :rules="[{required:true}]">
          <el-select v-model="podrForm.podr_prod_plmtrl_list[scope.$index].mtrl_unit" placeholder="请选择单位"  clearable>
            <el-option
              v-for="item in mtrlTypeGroupOpt"
              :key="item.id"
              :label="item.param1"
              :value="item.param1">
            </el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="单价">
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'podr_prod_plmtrl_list.'+scope.$index+'.mtrl_prod_price'">
          <el-input show-word-limit maxlength="11" v-model="podrForm.podr_prod_plmtrl_list[scope.$index].mtrl_prod_price" @input ="podrForm.podr_prod_plmtrl_list[scope.$index].mtrl_prod_price = helper.keepTNum1(podrForm.podr_prod_plmtrl_list[scope.$index].mtrl_prod_price)"  @change="priceChange(scope)" placeholder="暂无单价"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {get,post} from "@api/request";
import {mtrlAPI} from "@api/modules/mtrl";
export default {
  name:"ProdEditPrcsChild",
  props:{
    podrForm:{
      type: Object,
      required:true,
    },
    mtrlTypeGroupOpt:{
      type:Array,
      required:true,
    },
    plmtrlShow:{
      type:Boolean,
      required:true,
    },
    isShow:{
      type:Boolean,
      required:true,
    }
  },
  data(){
    return {
      selectionsList:[],
      flag:true,
      compList:['复合','印花','电绣'],
      show:false
    }
  },
  created(){
    this.initData()
  },
  methods:{
    initData(){
    },
    compListChange(val,num){
      this.$emit('compListChange',val,num)
    },
    // 删除
    handleClose(scope,val){
      if(!this.isShow){
        this.$emit('deletePlmtrl',scope,val)
      }
    },
    // 下拉框回显
    plmtrlChange(scope,val){
      if(!this.isShow){
        this.flag = false;
        this.$emit("plmtrlChangeIds",scope,val)
        this.$nextTick(()=>{
          this.flag=true;
        })
      }
    },
    // 选择框输入值改变裁片选择
    plmtrlChangeCut(scope){
      let temp = 0;
      // get(mtrlAPI.judgeMtrlNo,{mtrl_no:scope.row.mtrl_no})
      // .then((res)=>{
      //   if(res.data.code === 0){
          for(let i=0;i<this.podrForm.podr_prod_plmtrl_list.length;i++){
            if(this.podrForm.podr_prod_plmtrl_list[i].mtrl_no === scope.row.mtrl_no){
              temp += 1
            }
          }
          if(temp >1){
            scope.row.mtrl_no = null
            this.$message.error('物料编号重复，请重新填写！')
          }else{
            this.$emit("plmtrlChangeCut",scope)
          }
      //   }else{
      //     scope.row.mtrl_no = null
      //     this.$message.error('物料编号重复，请重新填写！')
      //     this.$emit('plmtrlShowChange')
      //   }
      // })
      // .catch(()=>{})
    },
    priceChange(scope){
      scope.row.mtrl_prod_price = this.helper.calcPrice(scope.row.mtrl_prod_price,4,1000000)
    },
    //prcs清单选择框
    handleSelectionChange2(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit("handleSelectionChange2",this.selectionsList);
    },
  }
}
</script>

<style lang="scss">
.vd_dis {
  display: inline-block;
}
.vd_mrl{
  margin-left: 5px;
}
.vd_wid100{
  width: 100%;
}
.vd_font20{
  margin-top: 5px;
  font-size: 20px;
}
.vd_clire {
  background-color:#c4bcbc;
  border-radius:50%;
  padding: 2px !important;
  border: 0;
  color: #fff;
}
.vd_dis{
  width: 100%;
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
}
.vd_magr3{
  margin-right: 3px;
}
</style>